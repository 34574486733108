import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import type {
  DialogContentProps,
  DialogProps,
  DialogTriggerProps,
} from '@radix-ui/react-dialog';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { FC, PropsWithChildren } from 'react';
import toast from 'react-hot-toast';
import type { InferType } from 'yup';
import { object, string } from 'yup';

import useForm from '@/hooks/useForm';
import { queries } from '@/queries';
import type { getApiKeys } from '@/services/apiKey';
import { deleteApiKey, postApiKey } from '@/services/apiKey';
import { dateFormatter } from '@/utils/date';

import Alert from '../Alert';
import Button from '../Button';
import Label from '../Form/Fields/Label';
import TextInputField from '../Form/Fields/TextInputField';
import Text from '../Text';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from '../ui/dialog';

const APIKey = () => {
  const { data } = useQuery({
    ...queries.apiKeys.list,
  });

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex-1 divide-x">
        {data?.results.map((apiKey) => (
          <ApiKeyElement key={apiKey.prefix} {...apiKey} />
        ))}
      </div>
      <GenerateAPIKeyDialog dialogTriggerProps={{ asChild: true }}>
        <Button className="self-end">Generate API key</Button>
      </GenerateAPIKeyDialog>
    </div>
  );
};

export default APIKey;

type ApiKeyElementProps = Awaited<
  ReturnType<typeof getApiKeys>
>['results'][number];

const ApiKeyElement: FC<ApiKeyElementProps> = ({
  created,
  expiryDate,
  name,
  prefix,
}) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => deleteApiKey(prefix),
    mutationKey: ['deleteApiKey'],
    onSuccess: () => {
      queryClient.invalidateQueries(queries.apiKeys.list);
    },
  });
  return (
    <div className="flex justify-between border-b py-2 text-black" key={prefix}>
      <div>
        <Text className="font-semibold">{name}</Text>
        <Text variant="body-caption" className="text-error">
          {expiryDate
            ? `Expires: ${dateFormatter({
                date: expiryDate,
                format: { dateStyle: 'full' },
              })}`
            : 'This token has no expiration date'}
        </Text>
      </div>
      <div className="flex items-center gap-2">
        <Text variant="body-caption" className="text-neutral-800">
          Created:{' '}
          {dateFormatter({
            date: created,
            format: { dateStyle: 'medium' },
          })}
        </Text>
        <Button
          size="sm"
          variant="secondary"
          className="!border-error font-semibold text-error"
          onClick={() => mutate()}
          isLoading={isLoading}
          confirm="Are you sure you want to delete this API key? This action cannot be undone"
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

type GenerateAPIKeyDialogProps = {
  dialogTriggerProps?: DialogTriggerProps;
  dialogProps?: DialogProps;
  dialogContentProps?: DialogContentProps;
};
const GenerateAPIKeyDialog: FC<
  PropsWithChildren<GenerateAPIKeyDialogProps>
> = ({ children, dialogContentProps, dialogProps, dialogTriggerProps }) => {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<InferType<typeof schema>>({ resolver: yupResolver(schema) });

  const { mutate, isLoading, status, data } = useMutation({
    mutationFn: postApiKey,
    mutationKey: ['generateApiKey'],
    onSuccess: () => {
      queryClient.invalidateQueries(queries.apiKeys.list);
    },
  });

  const onCopy = (key: string) => {
    try {
      navigator.clipboard.writeText(key);
      toast.success('Copied to clipboard');
    } catch (_e) {
      toast.error('Something went wrong');
    }
  };

  return (
    <Dialog {...dialogProps}>
      <DialogTrigger {...dialogTriggerProps}>{children}</DialogTrigger>
      <DialogContent {...dialogContentProps}>
        {data && status === 'success' ? (
          <>
            <DialogHeader>Success</DialogHeader>
            <Alert
              label="Make sure to copy your API key now. You won’t be able to see it again!"
              variant="critical"
            />
            <div>
              <Label className="text-neutral-800">API Key</Label>
              <div className="relative">
                <TextInputField
                  variant="transparentNeutralBorder"
                  readOnly
                  className="text-black"
                  value={data.apiKey}
                  canHaveErrorMessage={false}
                />
                <Button
                  onClick={() => onCopy(data.apiKey)}
                  className="!absolute right-2 top-1/2 -translate-y-1/2"
                >
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <DialogHeader>New API key</DialogHeader>
            <form
              onSubmit={handleSubmit((formData) => mutate(formData.name))}
              className="flex flex-col gap-2"
            >
              <TextInputField
                containerClassName="!gap-2"
                variant="transparentNeutralBorder"
                labelClassName="text-neutral-800"
                label="Note"
                placeholder="What's this token for?"
                className="text-black"
                error={errors.name?.message}
                {...register('name')}
              />
              <Button isLoading={isLoading} type="submit">
                Generate
              </Button>
            </form>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const schema = object({
  name: string().required(),
});
