import { create } from 'zustand';

export type Filters = {
  jobStart: string[];
  availability: string[];
  seniority: string[];
  relocation: string[];
  country: { value: number; label: string }[];
  city: { value: number; label: string }[];
  languages: { id: number; name: string; level: number }[];
  skills: string[];
  keywords: { keywords: string[]; suggestedKeywords: string[] };
};

type DashboardFilters = {
  filters: Filters;
  setFilters: (filters: Filters) => void;
};

export const defaultFilters = {
  availability: [],
  city: [],
  country: [],
  jobStart: [],
  keywords: { keywords: [], suggestedKeywords: [] },
  languages: [],
  relocation: [],
  seniority: [],
  skills: [],
};

export const useDashboardFiltersStore = create<DashboardFilters>()((set) => ({
  filters: defaultFilters,
  setFilters: (filters: Filters) => set({ filters }),
}));
