/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { Fragment } from 'react';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { useBreadcrumbsCustomPaths } from '@/hooks/useBreadcrumbsCustomPaths';
import { capitalizeFirstLetter } from '@/utils/cvGenerator';

type Props = {
  theme?: 'dark' | 'light';
};
export const DashboardBreadcrumbs: FC<Props> = ({ theme = 'dark' }) => {
  const router = useRouter();
  const splitPath = router.pathname.split('/').filter((part) => part !== '');
  const { id } = router.query;
  const { customPaths } = useBreadcrumbsCustomPaths();

  const beautifyPath = (part: string, pathname: string) => {
    return customPaths[pathname]
      ? customPaths[pathname]
      : part
          .split('-')
          .map((partSegment) => capitalizeFirstLetter(partSegment))
          .join(' ');
  };

  return (
    <Breadcrumb>
      <BreadcrumbList
        className={classNames({ 'text-neutral-900': theme === 'light' })}
      >
        {splitPath.map((part, index, arr) => {
          const pathname = `/${splitPath.slice(0, index + 1).join('/')}`;
          return (
            <Fragment key={index}>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link
                    href={{
                      pathname,
                      query: { id: part === '[id]' ? id : undefined },
                    }}
                  >
                    {beautifyPath(part, pathname)}
                  </Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              {index !== arr.length - 1 && <BreadcrumbSeparator />}
            </Fragment>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
