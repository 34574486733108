import { faAngleRight, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@iconify/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronDown } from 'lucide-react';
import { type FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import type { InferType } from 'yup';
import { array, number, object, string } from 'yup';

import useForm from '@/hooks/useForm';
import { useGetReasoningSearch } from '@/hooks/useGetReasoningSearch';
import { useLanguages } from '@/hooks/useLanguages';
import { useOptimisticUpdate } from '@/hooks/useOptimisticUpdate';
import { useSkillsOptions } from '@/hooks/useSkillsOptions';
import { queries } from '@/queries';
import {
  availabilityOptions,
  experienceOptions,
  jobStartOptions,
} from '@/services/externalCandidates';
import type { getReasoningSearch } from '@/services/reasoningSearch';
import {
  patchReasoningSearch,
  postReasoningSearch,
  transformApiOfferSearch,
} from '@/services/reasoningSearch';
import { useDashboardFiltersStore } from '@/stores/useDashboardFiltersStore';
import { useDashboard } from '@/stores/useDashboardStore';

import Button from '../Button';
import CheckboxList from '../Form/Fields/CheckboxList';
import Label from '../Form/Fields/Label';
import SelectValuePair from '../Form/Fields/SelectValuePair';
import SuggestedCitiesSelect from '../Form/Fields/SuggestedCitiesSelect';
import SuggestedCountriesSelect from '../Form/Fields/SuggestedCountriesSelect';
import { Select } from '../Select';
import Text from '../Text';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion';
import Keywords from './Keywords';

export const LANGUAGES_LEVEL_OPTIONS = [
  { label: 'Basic', value: 0 },
  { label: 'Intermediate', value: 1 },
  { label: 'Advanced', value: 2 },
  { label: 'Near-native', value: 3 },
  { label: 'Native', value: 4 },
];

const DashboardFilters: FC<{}> = () => {
  const queryClient = useQueryClient();
  const [navigationExpanded, setNavigationExpanded] = useState(false);
  const { search, searchType } = useDashboard();

  const { filters, setFilters } = useDashboardFiltersStore();

  const { data: reasoningSearchPostResult } = useQuery({
    queryFn: () => postReasoningSearch(search),
    queryKey: ['reasoningSearchPost', search],
    enabled: searchType === 'advanced' && search.length > 0,
    staleTime: Infinity,
  });

  const { setOptimisticData } = useOptimisticUpdate<
    Awaited<ReturnType<typeof getReasoningSearch>>
  >(queries.reasoningSearch.detail(reasoningSearchPostResult?.id).queryKey);

  const { data: reasoningSearchResult } = useGetReasoningSearch(
    reasoningSearchPostResult?.id
  );

  const [localSkill, setLocalSkill] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [localCountry, setLocalCountry] = useState<{
    label: string;
    value: number;
  } | null>(null);
  const [localCity, setLocalCity] = useState<{
    label: string;
    value: number;
  } | null>(null);

  const {
    register,
    watch,
    reset,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<InferType<typeof schema>>({
    defaultValues: filters,
  });

  const { data: languages } = useLanguages();
  const { hardSkillsOptions } = useSkillsOptions();

  const { mutate } = useMutation({
    mutationFn: patchReasoningSearch,
    onSuccess: (response) => {
      queryClient.invalidateQueries([
        ...queries.externalCandidates.list._def,
        { filtersId: reasoningSearchPostResult!.id },
      ]);
      setOptimisticData(() => transformApiOfferSearch(response));
    },
  });
  const languagesOptions = languages?.results.map((val) => ({
    label: val.name,
    value: val.id,
  }));

  const onReset = () => {
    if (searchType === 'simple') return reset(filters);
    if (searchType === 'advanced' && !!reasoningSearchResult?.aiParsedFilters) {
      reset({
        ...reasoningSearchResult?.aiParsedFilters,
        keywords: reasoningSearchResult?.keywords,
      });
    }
  };

  useEffect(() => {
    if (!reasoningSearchResult && !filters) return;
    onReset();
  }, [
    searchType,
    JSON.stringify(filters),
    JSON.stringify(reasoningSearchResult?.aiParsedFilters),
    JSON.stringify(reasoningSearchResult?.keywords),
  ]);

  return (
    <motion.div
      animate={{ width: navigationExpanded ? '25rem' : '4rem' }}
      className={classNames(
        'z-10 h-full overflow-y-auto overflow-x-hidden bg-neutral-1000 px-4 py-6',
        { 'overflow-y-hidden': !navigationExpanded }
      )}
    >
      <form
        onSubmit={handleSubmit(({ keywords, ...data }) => {
          if (searchType === 'simple') {
            setFilters({
              ...data,
              keywords,
            });
            return;
          }
          if (searchType === 'advanced' && !!reasoningSearchPostResult?.id) {
            mutate({
              id: reasoningSearchPostResult!.id,
              formData: {
                ...reasoningSearchResult,
                keywords,
                aiParsedFilters: {
                  ...reasoningSearchResult?.aiParsedFilters,
                  ...data,
                },
              },
            });
          }
        })}
      >
        <AnimatePresence>
          {!navigationExpanded && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute top-40"
            >
              <Icon
                icon="material-symbols-light:filter-alt"
                className="text-3xl"
              />
            </motion.div>
          )}
        </AnimatePresence>
        <motion.div
          animate={{ width: navigationExpanded ? '25rem' : '4rem' }}
          className="absolute"
        >
          <button
            type="button"
            className="absolute right-0 top-[8.5rem] h-8 w-6  rounded-[0.25rem] bg-primary-800"
            onClick={() => setNavigationExpanded((prev) => !prev)}
          >
            <FontAwesomeIcon
              icon={faAngleRight}
              className={classNames('transition-transform', {
                'rotate-180': navigationExpanded,
              })}
            />
          </button>
        </motion.div>
        <AnimatePresence>
          {navigationExpanded && (
            <motion.div
              animate={{
                opacity: 1,
              }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              className=""
            >
              {searchType === 'advanced' && (
                <>
                  <Text variant="jb-body-medium">
                    ({reasoningSearchResult?.keywords.keywords.length}) Detected
                    Filters
                  </Text>
                  <Controller
                    control={control}
                    name="keywords"
                    render={({ field: { onChange, value } }) => (
                      <Keywords onChange={onChange} value={value} />
                    )}
                  />
                </>
              )}

              <Accordion defaultValue="filters" type="single" collapsible>
                <AccordionItem
                  className="flex flex-col gap-2 border-b-0"
                  value="filters"
                >
                  <AccordionTrigger
                    className="[&[data-state=open]_svg]:rotate-180"
                    asChild
                  >
                    <button
                      type="button"
                      className="rounded-lg border border-neutral-700 bg-neutral-900 px-2 hover:!no-underline"
                    >
                      Filters
                      <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
                    </button>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="rounded-lg border border-neutral-700 bg-neutral-900 p-2">
                      <div className="gap-2 divide-y divide-neutral-700  [&_>*:first-child]:!pt-0 [&_>*:last-child]:!pb-0 [&_>*]:py-4">
                        <CheckboxList
                          {...register('seniority')}
                          values={watch('seniority') ?? []}
                          variant="dashboard"
                          checkboxCheckIconColor="white"
                          label="Experience Level"
                          options={experienceOptions}
                          labelClassName="text-jb-body-medium !p-0"
                          checkboxValueClassName={(checked) =>
                            classNames(
                              'bg-neutral-100 border-[#AAAAAA] transition-colors !rounded-md',
                              {
                                '!bg-primary-700 border-primary-700': checked,
                              }
                            )
                          }
                        />
                        <CheckboxList
                          values={watch('jobStart')}
                          {...register('jobStart')}
                          label="Job Start"
                          variant="dashboard"
                          checkboxCheckIconColor="white"
                          options={jobStartOptions}
                          labelClassName="text-jb-body-medium !p-0"
                          checkboxValueClassName={(checked) =>
                            classNames(
                              'bg-neutral-100 border-[#AAAAAA] transition-colors !rounded-md',
                              {
                                '!bg-primary-700 border-primary-700': checked,
                              }
                            )
                          }
                        />
                        <CheckboxList
                          values={watch('availability')}
                          {...register('availability')}
                          label="Availability"
                          variant="dashboard"
                          checkboxCheckIconColor="white"
                          options={availabilityOptions}
                          labelClassName="text-jb-body-medium !p-0"
                          checkboxValueClassName={(checked) =>
                            classNames(
                              'bg-neutral-100 border-[#AAAAAA] transition-colors !rounded-md',
                              {
                                '!bg-primary-700 border-primary-700': checked,
                              }
                            )
                          }
                        />
                        <CheckboxList
                          values={watch('relocation')}
                          {...register('relocation')}
                          label="Willingness to Relocate"
                          variant="dashboard"
                          checkboxCheckIconColor="white"
                          options={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                          ]}
                          labelClassName="text-jb-body-medium !p-0"
                          checkboxValueClassName={(checked) =>
                            classNames(
                              'bg-neutral-100 border-[#AAAAAA] transition-colors !rounded-md',
                              {
                                '!bg-primary-700 border-primary-700': checked,
                              }
                            )
                          }
                        />

                        <Controller
                          control={control}
                          name="country"
                          render={({ field: { onChange, value } }) => (
                            <div className="flex flex-col gap-4">
                              <Label className="-mb-2">Country</Label>

                              <div className="flex items-stretch gap-2">
                                <SuggestedCountriesSelect
                                  isClearable
                                  placeholder="Add Country"
                                  variant="gray"
                                  value={localCountry}
                                  onChange={setLocalCountry}
                                  menuPortalTarget={document.querySelector(
                                    'body'
                                  )}
                                  classNames={{
                                    singleValue: () =>
                                      'text-jb-body-medium text-white',
                                  }}
                                  className="flex-1"
                                />
                                <button
                                  disabled={localCountry === null}
                                  type="button"
                                  className="rounded-[0.25rem] bg-neutral-300 p-2"
                                  onClick={() => {
                                    onChange([...value, localCountry]);
                                    setLocalCountry(null);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-black"
                                  />
                                </button>
                              </div>
                              <div className="flex flex-wrap gap-2">
                                {value.map((country) => (
                                  <div
                                    key={country.value}
                                    className="flex w-min gap-2 whitespace-nowrap rounded-lg border border-neutral-600 bg-neutral-800 p-2"
                                  >
                                    {country.label}
                                    <button
                                      onClick={() =>
                                        onChange(
                                          value.filter(
                                            ({ value: countryValue }) =>
                                              countryValue !== country.value
                                          )
                                        )
                                      }
                                      type="button"
                                    >
                                      <FontAwesomeIcon icon={faX} />
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        />
                        <Controller
                          control={control}
                          name="city"
                          render={({ field: { onChange, value } }) => (
                            <div className="flex flex-col gap-4">
                              <Label className="-mb-2">City</Label>

                              <div className="flex items-stretch gap-2">
                                <SuggestedCitiesSelect
                                  isClearable
                                  variant="gray"
                                  placeholder="Add City"
                                  value={localCity}
                                  onChange={setLocalCity}
                                  menuPortalTarget={document.querySelector(
                                    'body'
                                  )}
                                  classNames={{
                                    singleValue: () =>
                                      'text-jb-body-medium text-white',
                                  }}
                                  className="flex-1"
                                />
                                <button
                                  disabled={localCity === null}
                                  type="button"
                                  className="rounded-[0.25rem] bg-neutral-300 p-2"
                                  onClick={() => {
                                    onChange([...value, localCity]);
                                    setLocalCity(null);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-black"
                                  />
                                </button>
                              </div>
                              <div className="flex flex-wrap gap-2">
                                {value.map((city) => (
                                  <div
                                    key={city.value}
                                    className="flex w-min gap-2 whitespace-nowrap rounded-lg border border-neutral-600 bg-neutral-800 p-2"
                                  >
                                    {city.label}
                                    <button
                                      onClick={() =>
                                        onChange(
                                          value.filter(
                                            ({ value: cityValue }) =>
                                              cityValue !== city.value
                                          )
                                        )
                                      }
                                      type="button"
                                    >
                                      <FontAwesomeIcon icon={faX} />
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        />

                        <Controller
                          control={control}
                          name="languages"
                          render={({ field: { onChange, value } }) => (
                            <SelectValuePair
                              mainLabel="Languages"
                              type="select"
                              placeholders={['Language', 'Level']}
                              options={languagesOptions ?? []}
                              secondOptions={LANGUAGES_LEVEL_OPTIONS}
                              onChange={(newValue) =>
                                onChange(
                                  newValue.map(([id, level]) => ({
                                    id,
                                    level,
                                    name: languagesOptions?.find(
                                      ({ value: optionId }) => optionId === id
                                    )?.label,
                                  }))
                                )
                              }
                              value={
                                value.length >= 1
                                  ? value.map(({ id, level }) => [id, level])
                                  : [[null, null]]
                              }
                              addButtonsVariant="neutral300"
                              deleteButtonsVariant="neutral300"
                              selectVariant="grayWhiteText"
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="skills"
                          render={({ field: { onChange, value } }) => (
                            <div className="flex flex-col gap-4">
                              <Label className="-mb-2">Skills</Label>
                              <div className="flex items-stretch gap-2">
                                <Select
                                  isClearable
                                  variant="grayWhiteText"
                                  onChange={(newSkill) =>
                                    setLocalSkill(newSkill)
                                  }
                                  value={localSkill}
                                  options={hardSkillsOptions}
                                  classNames={{
                                    control: () => 'w-full',
                                  }}
                                  className="flex-1"
                                  menuPlacement="top"
                                  menuPortalTarget={document.querySelector(
                                    'body'
                                  )}
                                />
                                <button
                                  disabled={localSkill === null}
                                  type="button"
                                  className="rounded-[0.25rem] bg-neutral-300 p-2"
                                  onClick={() => {
                                    onChange([
                                      ...value,
                                      localSkill?.label ?? '',
                                    ]);
                                    setLocalSkill(null);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-black"
                                  />
                                </button>
                              </div>
                              <div className="flex flex-wrap gap-2">
                                {value.map((skill) => (
                                  <div
                                    key={skill}
                                    className="flex w-min gap-2 whitespace-nowrap rounded-lg border border-neutral-600 bg-neutral-800 p-2"
                                  >
                                    {skill}
                                    <button
                                      onClick={() =>
                                        onChange(
                                          value.filter(
                                            (skillValue) => skillValue !== skill
                                          )
                                        )
                                      }
                                      type="button"
                                    >
                                      <FontAwesomeIcon icon={faX} />
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        />
                      </div>
                      <AnimatePresence />
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
              {isDirty && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="sticky -bottom-6 -mx-2 -mb-2 mt-4 flex bg-neutral-1000/90 px-2 py-4 backdrop-blur-sm"
                >
                  <Button
                    onClick={onReset}
                    className="w-full"
                    variant="unstyled"
                  >
                    Cancel
                  </Button>
                  <Button className="w-full" type="submit">
                    Apply Filter
                  </Button>
                </motion.div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </form>
    </motion.div>
  );
};

export default DashboardFilters;

const schema = object({
  jobStart: array().of(string().defined()).defined(),
  availability: array().of(string().defined()).defined(),
  seniority: array().of(string().defined()).defined(),
  relocation: array().of(string().defined()).defined(),
  country: array()
    .of(object({ value: number().defined(), label: string().defined() }))
    .defined(),
  city: array()
    .of(object({ value: number().defined(), label: string().defined() }))
    .defined(),
  languages: array()
    .of(
      object({
        id: number().defined(),
        name: string().defined(),
        level: number().defined(),
      })
    )
    .defined(),
  skills: array().of(string().defined()).defined(),
  keywords: object({
    keywords: array().of(string().defined()).defined(),
    suggestedKeywords: array().of(string().defined()).defined(),
  }).defined(),
});
