import { type FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import type { InferType } from 'yup';
import { mixed, object, string } from 'yup';

import { useSettings } from '@/hooks/useSettings';
import {
  LanguageLevelPredefinedOptions,
  type Settings,
  TranslateLanguages,
} from '@/services/settings';
import { parseEnumToOptions } from '@/utils/parseEnumToOptions';

import { Select } from '../Select';
import { Accordion, AccordionContent, AccordionItem } from '../ui/accordion';
import CustomLanguageLevel from './CustomLanguageLevel';

type Props = {
  data?: Settings;
};

const Language: FC<Props> = ({ data }) => {
  const { mutate } = useSettings();
  const { control, watch } = useForm<InferType<typeof schema>>({
    defaultValues: {
      languageLevelPredefined: data?.languageLevelPredefined ?? null,
      preferredCvLanguage:
        data?.preferredCvLanguage ?? TranslateLanguages.ENGLISH,
    },
  });

  useEffect(() => {
    if (
      JSON.stringify([
        data?.languageLevelPredefined,
        data?.preferredCvLanguage,
      ]) !==
      JSON.stringify([
        watch('languageLevelPredefined'),
        watch('preferredCvLanguage'),
      ])
    ) {
      mutate(watch());
    }
  }, [JSON.stringify(watch())]);

  const optionsLanguageLevelPredefined = [
    {
      label: 'CEFR',
      value: LanguageLevelPredefinedOptions.CEFR,
      customLabelMenuElement: (
        <div>
          <p>CEFR</p>
          <p className="opacity-80">A1 - C2</p>
        </div>
      ),
    },
    {
      label: 'Numeric',
      value: LanguageLevelPredefinedOptions.NUMERIC,
      customLabelMenuElement: (
        <div>
          <p>Numeric</p>
          <p className="opacity-80">1 - 5</p>
        </div>
      ),
    },
    {
      label: 'Adjective',
      value: LanguageLevelPredefinedOptions.ADJECTIVE,
      customLabelMenuElement: (
        <div>
          <p>Adjective</p>
          <p className="opacity-80">Beginner - Native</p>
        </div>
      ),
    },
    { label: 'Custom', value: LanguageLevelPredefinedOptions.CUSTOM },
  ];

  const optionsPreferredCvLanguage = parseEnumToOptions(TranslateLanguages);
  return (
    <>
      <form className="flex flex-col gap-4">
        <Controller
          control={control}
          name="preferredCvLanguage"
          render={({ field: { name, onChange, value, onBlur } }) => (
            <Select
              name={name}
              label="Output resume language"
              className="max-w-[300px]"
              variant="light"
              sizeVariant="sm"
              onChange={(newValue) => onChange(newValue?.value)}
              value={optionsPreferredCvLanguage.find(
                (option) => option.value === value
              )}
              onBlur={onBlur}
              options={optionsPreferredCvLanguage}
            />
          )}
        />
        <Controller
          control={control}
          name="languageLevelPredefined"
          render={({ field: { name, onChange, value, onBlur } }) => (
            <Select
              name={name}
              label="Language prooficiency level format"
              className="max-w-[300px]"
              variant="light"
              sizeVariant="sm"
              onChange={(newValue) => onChange(newValue?.value)}
              value={optionsLanguageLevelPredefined.find(
                (option) => option.value === value
              )}
              onBlur={onBlur}
              options={optionsLanguageLevelPredefined}
            />
          )}
        />
      </form>
      <Accordion
        value={data?.languageLevelPredefined ?? undefined}
        type="single"
      >
        <AccordionItem
          value={LanguageLevelPredefinedOptions.CUSTOM}
          className="border-b-0"
        >
          <AccordionContent>
            <CustomLanguageLevel data={data} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default Language;

export const schema = object({
  preferredCvLanguage: string()
    .defined()
    .oneOf([...Object.values(TranslateLanguages)]),
  languageLevelPredefined: mixed<LanguageLevelPredefinedOptions | ''>()
    .nullable()
    .defined()
    .oneOf([...Object.values(LanguageLevelPredefinedOptions), null, '']),
});
