import { faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@iconify/react';
import { type FC, useState } from 'react';

import { capitalizeFirstLetter } from '@/utils/cvGenerator';

import Label from '../Form/Fields/Label';
import TextInputField from '../Form/Fields/TextInputField';
import Text from '../Text';

type Props = {
  value: { keywords: string[]; suggestedKeywords: string[] };
  onChange: (value: {
    keywords: string[];
    suggestedKeywords: string[];
  }) => void;
};

const Keywords: FC<Props> = ({ onChange, value }) => {
  const [keyword, setKeyword] = useState('');
  return (
    <>
      <div className="mt-4">
        <Label className="text-body-small">Add new keyword</Label>
        <div className="flex gap-2">
          <TextInputField
            placeholder="Add Keyword..."
            sizeVariant="xs"
            variant="grayOutline"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <button
            disabled={keyword.length <= 0}
            onClick={() => {
              onChange({
                keywords: [...value.keywords, keyword],
                suggestedKeywords: value.suggestedKeywords,
              });
              setKeyword('');
            }}
            type="button"
            className="rounded-[0.25rem] bg-neutral-300 p-2"
          >
            <FontAwesomeIcon className="text-black" icon={faPlus} />
          </button>
        </div>
      </div>
      <div className="flex flex-wrap gap-2 border-b border-neutral-700 py-4">
        {value.keywords?.map((singleKeyword) => (
          <div
            key={singleKeyword}
            className="flex w-min items-center gap-2 whitespace-nowrap rounded-lg border border-neutral-600 bg-neutral-800 p-2"
          >
            <Text variant="jb-body-medium">
              {capitalizeFirstLetter(singleKeyword)}
            </Text>
            <button
              type="button"
              onClick={() =>
                onChange({
                  keywords: value.keywords.filter((k) => k !== singleKeyword),
                  suggestedKeywords: value.suggestedKeywords,
                })
              }
            >
              <FontAwesomeIcon icon={faX} />
            </button>
          </div>
        ))}
      </div>
      {value.suggestedKeywords.length > 0 && (
        <div className="py-4">
          <div className="mb-2 flex items-center">
            <Icon icon="bi-stars" className="text-primary-300" />
            <Text variant="body-small" className="text-primary-300">
              Add Suggested Keywords
            </Text>
          </div>

          <div className="flex flex-wrap gap-2 ">
            {value?.suggestedKeywords.map((singleKeyword) => (
              <div
                key={singleKeyword}
                className="flex w-min items-center gap-2 whitespace-nowrap rounded-lg border border-primary-500 bg-neutral-800 p-2"
              >
                <Text variant="jb-body-medium">
                  {capitalizeFirstLetter(singleKeyword)}
                </Text>
                <button
                  type="button"
                  onClick={() =>
                    onChange({
                      keywords: [...value.keywords, singleKeyword],
                      suggestedKeywords: value.suggestedKeywords.filter(
                        (k) => k !== singleKeyword
                      ),
                    })
                  }
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Keywords;
