import type { SelectProps } from '@mindpal-co/mindpal-ui';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import type { Props } from '@/components/Select';
import { Select } from '@/components/Select';
import { useDebounce } from '@/hooks/useDebounce';
import { queries } from '@/queries';

const SuggestedCitiesSelect = <
  T extends object & { isFixed?: boolean },
  IsMulti extends boolean = false,
  IsCreatable extends boolean = false,
  IsAsync extends boolean = false,
  IsAsyncCreatable extends boolean = false
>(
  selectProps: SelectProps<T, IsMulti, IsCreatable, IsAsync, IsAsyncCreatable> &
    Props
) => {
  const [input, setInput] = useState('');
  const debouncedInput = useDebounce(input);

  const { data: citiesData, isFetching } = useQuery({
    ...queries.common.suggestedCities({
      limit: 10,
      offset: 0,
      query: debouncedInput,
    }),
    enabled: debouncedInput.length > 0,
  });

  const citiesOptions = citiesData?.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
  return (
    <Select
      isLoading={input !== debouncedInput || isFetching}
      onInputChange={(e) => {
        if (e.length <= 0 && input.length > 0) return;
        setInput(e ?? '');
      }}
      options={citiesOptions}
      {...(selectProps as any)}
    />
  );
};

export default SuggestedCitiesSelect;
