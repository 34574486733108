import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBriefcase,
  faIdCard,
  faLocationDot,
  faProjectDiagram,
  faSchool,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@iconify/react';
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@mindpal-co/mindpal-ui';
import { type FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import type { InferType } from 'yup';
import { boolean, object } from 'yup';

import { useSettings } from '@/hooks/useSettings';
import type { Settings } from '@/services/settings';

import Label from '../Form/Fields/Label';
import Text from '../Text';
import { Switch } from '../ui/switch';
import { TooltipContent } from '../ui/tooltip';

type Props = {
  data?: Settings;
};
type FormSchema = InferType<typeof schema>;
const Anonymize: FC<Props> = ({ data }) => {
  const { mutate } = useSettings();
  const { control, watch } = useForm<FormSchema>({
    defaultValues: {
      anonymizeEducation: data?.anonymizeEducation ?? false,
      anonymizeLocation: data?.anonymizeLocation ?? false,
      anonymizeName: data?.anonymizeName ?? false,
      anonymizeProjects: data?.anonymizeProjects ?? false,
      anonymizeWorkExperience: data?.anonymizeWorkExperience ?? false,
    },
  });
  useEffect(() => {
    if (
      JSON.stringify([
        data?.anonymizeEducation,
        data?.anonymizeLocation,
        data?.anonymizeName,
        data?.anonymizeProjects,
        data?.anonymizeWorkExperience,
      ]) !== JSON.stringify([watch()])
    ) {
      mutate(watch());
    }
  }, [JSON.stringify(watch())]);

  const switches: { name: keyof FormSchema; label: string; icon: IconProp }[] =
    [
      { name: 'anonymizeLocation', label: 'Location', icon: faLocationDot },
      { name: 'anonymizeName', label: 'Name', icon: faIdCard },
      { name: 'anonymizeProjects', label: 'Projects', icon: faProjectDiagram },
      {
        name: 'anonymizeWorkExperience',
        label: 'Work experience',
        icon: faBriefcase,
      },
      { name: 'anonymizeEducation', label: 'Education', icon: faSchool },
    ];

  return (
    <div className="flex flex-col gap-4">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger className="flex w-fit flex-row items-center gap-2">
            <Icon
              icon="material-symbols-light:info-outline"
              className="text-neutral-500"
              fontSize={20}
            />
            <Text className="font-semibold text-neutral-1000">Anonymize:</Text>
          </TooltipTrigger>
          <TooltipContent className="max-w-xs rounded-lg p-4 text-neutral-1000">
            <Text variant="body-caption">
              When you check `Anonymize this resume` while uploading a resume,
              only the selected options from these settings will be anonymized
              when the anonymization process starts
            </Text>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <div className="grid grid-cols-3 gap-x-16 gap-y-4">
        {switches.map(({ name, label, icon }) => (
          <Controller
            key={name}
            control={control}
            name={name}
            render={({ field: { value, onChange } }) => (
              <div className="flex w-full flex-row justify-between border-b border-neutral-200 py-4">
                <Label className="flex flex-row items-center gap-3 text-neutral-1000">
                  <FontAwesomeIcon icon={icon} className="w-5" />
                  {label}
                </Label>
                <Switch checked={value} onCheckedChange={onChange} name="sd" />
              </div>
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default Anonymize;

const schema = object({
  anonymizeEducation: boolean().defined(),
  anonymizeLocation: boolean().defined(),
  anonymizeName: boolean().defined(),
  anonymizeProjects: boolean().defined(),
  anonymizeWorkExperience: boolean().defined(),
});
