import { useQuery } from '@tanstack/react-query';

import { queries } from '@/queries';

export const useGetReasoningSearch = (id?: number) => {
  return useQuery({
    ...queries.reasoningSearch.detail(id!),
    enabled: !!id,
    refetchInterval: (queryData) => {
      if (!queryData) return false;

      if (Object.values(queryData.processingState).every((v) => v === true))
        return false;
      return 1000;
    },
  });
};
