import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@iconify/react';
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@mindpal-co/mindpal-ui';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { type FC, type PropsWithChildren, useEffect, useState } from 'react';

import LogoText from '@/assets/logo-text-only.svg';
import Logo from '@/assets/logo-white.svg';
import Text from '@/components/Text';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { TooltipContent } from '@/components/ui/tooltip';
import { isDevelopment } from '@/config/config';
import { useLogout } from '@/hooks/useLogout';
import { useProfile } from '@/hooks/useProfile';
import { useRedirectAuthUsers } from '@/hooks/useRedirectAuthUsers';
import { createMetaTags } from '@/utils/seo';

import BackButton from '../../BackButton';
import CvUsage from '../../Header/CvUsage';
import RecruiterSettingsDialog from '../../RecruiterSettings/RecruiterSettingsDialog';
import DashboardFilters from '../Filters';
import { DashboardBreadcrumbs } from './Breadcrumbs';
import ChangelogButton from './ChangelogButton';

type Props = {
  theme?: 'dark' | 'light';
  showFilters?: boolean;
};

const Navigation: FC<PropsWithChildren<Props>> = ({
  children,
  theme = 'dark',
  showFilters = false,
}) => {
  useRedirectAuthUsers();
  const { pathname } = useRouter();
  const { data: profileData } = useProfile();
  const [navigationExpanded, setNavigationExpanded] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setNavigationExpanded(false);
  }, [router.pathname]);

  const links = [
    {
      name: 'Home',
      icon: 'material-symbols-light:space-dashboard-outline',
      href: '/dashboard',
    },
    {
      name: 'Generator',
      icon: 'material-symbols-light:dashboard-customize-outline',
      href: '/dashboard/generator',
    },
    {
      name: 'Collections',
      icon: 'material-symbols-light:folder-open-outline',
      href: '/dashboard/collections',
    },
    {
      name: 'Job Matching',
      icon: 'material-symbols-light:business-center-outline',
      href: '/dashboard/job-matching',
    },
  ];

  return (
    <>
      <Head>
        {createMetaTags({
          title: 'MindPal - Blind Resume',
          description: 'Generate resumes with the power of AI',
        })}
      </Head>
      <div
        className={classNames(
          'flex max-h-screen flex-1 overflow-hidden old-page-scrollbar',
          {
            'bg-neutral-100': theme === 'light',
          }
        )}
      >
        <motion.div
          layout
          className="absolute left-0 top-0 z-10 flex h-full shrink-0 flex-col justify-between bg-primary-800 px-4 py-8"
        >
          <button
            type="button"
            onClick={() => setNavigationExpanded((prev) => !prev)}
            className="absolute -right-8 bottom-[5.7rem] flex aspect-square w-8 items-center justify-center rounded-r-[0.25rem] bg-primary-800"
          >
            <FontAwesomeIcon
              icon={faAngleRight}
              className={classNames('transition-transform', {
                'rotate-180': navigationExpanded,
              })}
            />
          </button>
          <div className="flex flex-col">
            <div className="mb-12 ml-2 flex items-center">
              <Link
                className="flex items-center"
                href={profileData || isDevelopment ? '/dashboard' : '/'}
              >
                <Logo />
                <div
                  className={classNames('w-0 transition-all overflow-hidden', {
                    'w-32 ml-2': navigationExpanded,
                  })}
                >
                  <LogoText />
                </div>
              </Link>
            </div>
            <div className="flex flex-col gap-1">
              <NavigationTooltip
                tooltipDisabled={navigationExpanded}
                tooltipContent="What’s new?"
                key="What’s new?"
              >
                <ChangelogButton navigationExpanded={navigationExpanded} />
              </NavigationTooltip>
              {links.map(({ name, icon, href }) => (
                <NavigationTooltip
                  tooltipDisabled={navigationExpanded}
                  tooltipContent={name}
                  key={name}
                  asChild
                >
                  <Link
                    href={href}
                    className={classNames(
                      'h-min rounded-lg p-3 transition-colors hover:bg-primary-700 flex',
                      {
                        'bg-primary-700': pathname === href,
                      }
                    )}
                  >
                    <Icon icon={icon} className="text-2xl" />
                    <Text
                      className={classNames(
                        'w-0 transition-all overflow-hidden whitespace-nowrap',
                        {
                          'w-32 ml-3': navigationExpanded,
                        }
                      )}
                    >
                      {name}
                    </Text>
                  </Link>
                </NavigationTooltip>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <RecruiterSettingsDialog
              tooltipDisabled={navigationExpanded}
              dialogTriggerProps={{
                asChild: true,
              }}
            >
              <button
                type="button"
                className="flex h-min rounded-lg p-3 transition-colors hover:bg-primary-800"
              >
                <Icon
                  icon="material-symbols-light:settings-outline"
                  className="text-2xl "
                />
                <Text
                  className={classNames(
                    'w-0 transition-all overflow-hidden whitespace-nowrap',
                    {
                      'w-32 ml-3': navigationExpanded,
                    }
                  )}
                >
                  Resume settings
                </Text>
              </button>
            </RecruiterSettingsDialog>
            <UserDropdownWithTooltip tooltipDisabled={navigationExpanded} />
          </div>
        </motion.div>
        <div
          className={classNames('flex flex-1 !overflow-auto', {
            'ml-20': !showFilters,
          })}
        >
          {showFilters && (
            <div className="ml-20">
              <DashboardFilters />
            </div>
          )}
          <div
            className={classNames(
              'flex flex-1 flex-col gap-3 !overflow-auto px-6 pt-6',
              {
                'pr-14': pathname === '/dashboard/generator/[id]',
              }
            )}
          >
            <div className="flex shrink-0 items-center justify-between overflow-auto">
              <div className="flex gap-5">
                <BackButton theme={theme} />
                <DashboardBreadcrumbs theme={theme} />
              </div>
              <CvUsage />
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;

type NavigationTooltipProps = {
  tooltipDisabled?: boolean;
  tooltipContent: string;
  asChild?: boolean;
};

const NavigationTooltip: FC<PropsWithChildren<NavigationTooltipProps>> = ({
  tooltipContent,
  children,
  tooltipDisabled,
  asChild,
}) => {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild={asChild}>{children}</TooltipTrigger>
        <TooltipContent
          side="right"
          className={classNames('rounded-lg p-2', { hidden: tooltipDisabled })}
        >
          <Text variant="body-small" className="text-neutral-1000">
            {tooltipContent}
          </Text>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

type DropdownWithTooltipProps = {
  tooltipDisabled?: boolean;
};

const UserDropdownWithTooltip: FC<DropdownWithTooltipProps> = ({
  tooltipDisabled,
}) => {
  const { mutate } = useLogout();

  return (
    <TooltipProvider delayDuration={0}>
      <DropdownMenu>
        <Tooltip>
          <DropdownMenuTrigger asChild className="flex items-center gap-3">
            <TooltipTrigger>
              <div className="flex items-center justify-center p-3">
                <Icon
                  icon="material-symbols-light:person-outline"
                  className="text-2xl"
                />
                <Text
                  className={classNames(
                    'w-0 transition-all overflow-hidden whitespace-nowrap',
                    { 'w-32 ml-3': tooltipDisabled }
                  )}
                >
                  My profile
                </Text>
              </div>
            </TooltipTrigger>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => mutate()}>Logout</DropdownMenuItem>
          </DropdownMenuContent>
          <TooltipContent
            side="right"
            className={classNames('rounded-lg p-2', {
              hidden: tooltipDisabled,
            })}
          >
            <Text variant="body-small" className="text-neutral-1000">
              My profile
            </Text>
          </TooltipContent>
        </Tooltip>
      </DropdownMenu>
    </TooltipProvider>
  );
};
